$checkmark__primary_color: $success-color;
$checkmark__secondary_color: #fff;
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
  &.checkmark-small {
    width: 30px;
    height: 30px;
  }
  &.checkmark-medium {
    width: 100px;
    height: 100px;
  }
  &.checkmark-large {
    width: 180px;
    height: 180px;
  }
  margin: $margin auto;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $checkmark__secondary_color;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $checkmark__primary_color;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $checkmark__primary_color;
  fill: none;
  animation: stroke .6s $curve forwards;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

// Checkmark Animations
@keyframes stroke {
  100% { stroke-dashoffset: 0; }
}
@keyframes scale {
  0%, 100% { transform: none; }
  50% { transform: scale3d(1.1, 1.1, 1); }
}
@keyframes fill {
  100% { box-shadow: inset 0px 0px 0px 100px $checkmark__primary_color; }
}
