@charset "utf-8";
@import './settings.scss';
@import '~milligram/src/milligram.sass';
@import './checkmark.scss';

.secondary-color {
  color: $medium-grey;
}

// Layout
body {
  margin: 0 auto;
  text-align: center;
  padding-top: $margin;
}

.limited-width {
  max-width: 320px;
  margin: 0 auto;
}

// Certificates
// TODO rename class to card
.certificate-result {
  border-radius: 9px;
  box-shadow: 1px 1px 9px 1px rgba($primary-color, 0.1);
  max-width: 40rem;
  margin: $margin auto;
  padding: $margin;
  &.certificate-result-empty {
    padding: $margin*2 $margin;
  }
}


// Description Lists
.dl-with-accent {
  text-align: center;
  margin-bottom: 0;
  dd {
    color: $medium-grey;
    text-align: center;
    margin-inline-start: 0;
    margin-bottom: 0;
  }
  dt {
    @extend h4;
    margin-bottom: 2rem;
  }
  &.right-align {
    text-align: right;
    dd {
      color: $medium-grey;
      text-align: right;
    }
    dt {
      font-size: medium;
      margin-bottom: 0;
    }
  }
}

// Form elements
.button {
  margin:0;
  &.hollow {
    background-color: transparent;
    color: $color-secondary;
  }
  &.borderless {
    border: none;
  }
  &.secondary {
    background-color: transparent;
    color: $medium-grey;
  }
}
input[type='text'] {
    font-size: 1.6rem;
}

// Spacing
.margin-top {
  margin-top: $margin;
}
.margin-top-half {
  margin-top: $margin/2;
}
.margin-top-4x {
  margin-top: $margin*4;
}

.margin-bottom {
  margin-bottom: $margin;
}

.warning-icon::before {
  content: "\26A0";
  position: relative;
  left: -$margin/4;
  bottom: -1px;
}


// QR Code Reader
$qr-size: 240px;
.qr-reader {
  width: $qr-size;
  height: $qr-size;
  margin: 0 auto $margin;
  border: solid $margin #eee;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover!important;
  }
  &.legacy-mode {
    display: none;
  }
}

// Animations
.animation-shake {
  animation: shake .7s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}

// Loader
$loader-color: lighten($medium-grey, 20%);
.loader-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    border: 2px solid $loader-color;
    opacity: 1;
    border-radius: 50%;
    animation: loader-ripple 1.33s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) { animation-delay: -0.5s; }
  }
}
@keyframes loader-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
