$color-primary: #083849;
$color-alert: #d01919;
$color-white: #ffffff;

$primary-color: $color-primary;
$secondary-color: #767676;
$success-color: #3adb76;
$warning-color: #ffae00;
$alert-color: transparentize(#e90000,0.1);

$medium-grey: #999;

$margin: 2rem;
